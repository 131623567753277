const serviceApp = process.env.VUE_APP_FINANCE_SERVICE;

export default {
  methods: {
    async deletePI(PIId) {
      return await this.$http.delete(`v2/redirect?app=${serviceApp}&path=/api/pi/${PIId}`);
    },

    async getAffiliateMonthCommissions(statusId, affiliateId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/commission/this_month/${statusId}/${affiliateId}`
      );
    },

    async requestPayment(affiliateId) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/commission/request_payment/${affiliateId}`);
    },

    async toggleLiberateInvoice(id) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/invoice/toggle_liberate/${id}`);
    },

    async getAffiliateMonthCommissionsCSV(statusId, affiliateId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/commission/generate_csv/${statusId}/${affiliateId}`
      );
    },

    async getAffiliateSumAllByStateCommissions(affiliateId, statusId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/commission/sumallbystate/${affiliateId}/?ids=${statusId}`
      );
    },

    async getAffiliateCommissionsByState(affiliateId, statusId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/commission/bystate/${affiliateId}/?ids=${statusId}`
      );
    },

    async getAffiliateCommissionsByStateNoPaginate(affiliateId, statusId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/commission/bystate/${affiliateId}/?ids=${statusId}%26no-paginate=true`
      );
    },

    async getMonthCommissionsSum(statusId) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/commission/sum_this_month/${statusId}`);
    },

    async getCommissionSumByState(affiliateId, states) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/commission/sumallbystate/${affiliateId}/?ids=${states}`
      );
    },

    async getAffiliateByToken() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/affiliate-by-token`);
    },

    async getInvoices(statusId) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/invoice/affiliate_invoices/${statusId}`);
    },

    async affiliateInvoices(affiliateId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/invoice/affiliate_invoices?AffiliateId=${affiliateId}`
      );
    },

    async listCustomPaymentGroups() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/custom_payment_groups/`);
    },

    async listInvoicesPaymentsStep(filters) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/invoices_payments/affiliate?${filters}`);
    },
    
    async listInvoicesPaymentsAffiliate(filters) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/invoices_payments/affiliate?${filters}`);
    },

    async getOutboundInvoice(hashInvoice) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/outbound_invoice/public/${hashInvoice}`);
    },

    async getCustomPaymentGroupAffiliateByGroup(groupId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/custom_payment_groups_affiliate/get_by_group/${groupId}`
      );
    },

    async updateInvoicePayments(invoices) {
      return await this.$http.put(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/invoices_payments/`,
        params: {
          data: {
            invoices: `"${invoices}"`
          }
        }
      });
    },

    async infoPiUpdate(piId, infoPiParams) {
      return await this.$http.put(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/pi/${piId}`,
        params: {
          data: infoPiParams
        }
      });
    },

    async searchInvoice(searchInvoiceParams) {
      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/invoice/search`,
        params: {
          data: searchInvoiceParams
        }
      });
    },

    async requestAffiliatePayment(paymentRequest) {
      let path = this.user.profile == 'afiliado' ? `/api/v2/payment_requests` : `/api/payment_requests`;
      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `${path}`,
        params: {
          data: paymentRequest
        }
      });
    },

    async editInvoice(id, invoice_nums, total_received) {
      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/invoice/edit`,
        params: {
          data: {
            id: id,
            invoice_nums: invoice_nums,
            total_received: total_received
          }
        }
      });
    },

    async listAffiliateScheduledCommissions(affiliateId) {
      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/commission/admin_payments`,
        params: {
          data: {
            affiliateId: `"${affiliateId}"`
          }
        }
      });
    },

    async scheduleCommissionsPayment(commissions, date) {
      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/commission/schedule_payment`,
        params: {
          data: {
            commissions: `"${commissions}"`,
            date: `"${date}"`
          }
        }
      });
    },

    async searchAffiliateScheduledMonthCommissions(affiliateId) {
      return await this.$http.post(`/v2/redirect`, {
        app: `${serviceApp}`,
        path: `/api/commission/affiliate/search`,
        params: {
          data: {
            affiliateId: `"${affiliateId}"`
          }
        }
      });
    },

    async getStatements(affiliateId, dateStart, dateEnd) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/statements/${affiliateId}?dataInicio=${dateStart}%26dataFim=${dateEnd}`
      );
    }
  }
};
